import React from "react";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/layout";
import WorkMenu from "../components/work-menu";
import SEO from "../components/seo";

export const pageQuery = graphql`
  query WorkIndexQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 1980
              maxHeight: 968
              quality: 80
              srcSetBreakpoints: [350, 700, 1050, 1400]
            ) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`;

function WorkList({ data }) {
  const frontmatter = data.markdownRemark.frontmatter;
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.fluid
    : "";

  return (
    <Layout className="work-page work-index-page">
      <SEO title={"Work"} description={"bleh"} />
      <WorkMenu />
      {Image ? (
        <BackgroundImage
          fluid={Image}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={frontmatter.title + " - Featured image"}
          className="featured-image"
        />
      ) : (
        ""
      )}
    </Layout>
  );
}

export default WorkList;
